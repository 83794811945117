<template>
  <div class="orderMeasure">
    <el-table
      :data="tableData"
      stripe
      style="width: 100%"
      :header-cell-style="{
        'background-color': '#eee',
        color: '#333',
        fontSize: '16px',
      }"
    >
      <el-table-column prop="area" label="房屋面积(m²)" width="180" />
      <el-table-column
        prop="address"
        label="房屋所在省/市/区(县)"
        width="250"
      />
      <el-table-column prop="roomtype" label="区域" width="300" />
      <el-table-column prop="payment" label="付款金额" />
      <el-table-column prop="phone" label="联系方式" />
    </el-table>
    <div class="pagination">
      <el-pagination
        :page-size="12"
        background
        layout="prev, pager, next"
        :total="total"
        :current-page="form.page"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [
        {
          area: '',
          city: '',
          district: '',
          id: '',
          payment: '',
          phone: '',
          province: '',
          roomtype: '',
        },
      ],
      form: {
        page: 1,
        limit: 12,
      },
      total: 0,
    }
  },
  methods: {
    handleCurrentChange(page) {
      this.form.page = page
      this.getList()
    },
    getList() {
      this.$request.getShyUserList(this.form, (res) => {
        let list = res.data.data
        this.tableData = res.data.data
        list.filter((i, index) => {
          if (i.province) {
            this.tableData[index].address =
              i.province + '/' + i.city + '/' + i.district
          }
        })
        this.total = res.data.total
      })
    },
  },
  mounted() {
    this.getList()
  },
}
</script>

<style lang="less" scoped>
.orderMeasure {
  padding: 0 32px;
  .pagination {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0;
  }
}
</style>
